import { useCallback, useMemo, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import SelectPhotoPage from './SelectPhoto'
import ProductPage from './Product'
import PersonalizePage from './Personalize'
import PreviewPage from './Preview'
import PaymentPage from './Checkout'
import LoadingOverlay from './components/LoadingOverlay'
import ApiClient from '../../ApiClient'
import StripeCheckoutPage from './Checkout/StripeCheckout'
import OrderSuccess from './Checkout/Success'
import Cart from './Checkout/Cart'
import { create2DecimalsCurrencyFormatter } from '../../utils'
import usePrintify from './usePrintify'
import useRecordUserAction from '../../hooks/useRecordUserAction'
import LogsPanel from './LogsPanel'

export default function CreateFlow({
  api,
  openTermsOfUsePage,
  navigateBackToSource,
  isPrintifyPreviewLogsActive,
}: {
  api: ApiClient
  openTermsOfUsePage: () => void
  navigateBackToSource: () => void
  isPrintifyPreviewLogsActive: boolean
}) {
  const {
    isLoading,
    orderState,
    blueprint,
    variantProviders,
    createBaseOrder,
    updateQuantity,
    updateQuantityAndVariant,
    loadOrder,
    previewPhotosList,
    isCreatingBaseOrder,
    isGettingOrder,
    isGettingPreview,
    showNotAllLoadedError,
    regeneratePreview,
    fetchLogs,
  } = usePrintify(api)

  const navigate = useNavigate()
  const blueprintId = blueprint?.id
  const currency = blueprint?.currency || 'usd'
  const variants = blueprint?.variants || {}
  const blueprintTitle = blueprint?.title || ''
  const selectedVariantId = orderState.variantId
  const selectedVariant = selectedVariantId ? variants[selectedVariantId] : null

  const {
    photoProducts: {
      printify: {
        recordDidTapPersonalize,
        recordDidTapPreview,
        recordDidTapShoppingCart,
        recordDidTapToGetToCheckout,
        recordDidCompleteCheckout,
      },
    },
  } = useRecordUserAction(api)

  const [isLogsPageVisible, setIsLogsPageVisible] = useState(false)
  const [clientSecret, setClientSecret] = useState<string | null>(null)
  const [checkoutSessionId, setCheckoutSessionId] = useState<string | null>(
    null
  )

  const [confirmedOriginalDataUrl, setConfirmedOriginalDataUrl] = useState<
    string | null
  >(null)
  const [croppedDataUrl, setCroppedDataUrl] = useState<string | null>(null)
  const [candidateDateUrl, setCandidateDataUrl] = useState<string | null>(null)

  const [isCropping, setIsCropping] = useState(false)
  const [chargedAmount, setChargedAmount] = useState<number | null>(null)
  const [chargedCustomerEmail, setChargedCustomerEmail] = useState<
    string | null
  >(null)

  const uniqueColors = new Set<string>()
  const uniqueSizes = new Set<string>()

  variantProviders.forEach((provider) => {
    provider.variants.forEach((variant) => {
      const { color, hexColor, size } = variant.options
      if (color && hexColor) {
        uniqueColors.add(`${color}-${hexColor}`)
      }
      if (size) {
        uniqueSizes.add(size)
      }
    })
  })

  const hasMultipleColors = uniqueColors.size > 1
  const hasMultipleSizes = uniqueSizes.size > 1

  const createPaymentIntent = useCallback(
    async (orderId: string) => {
      const response = await api.printify.createPaymentIntent({
        baseOrderId: orderId,
        quantity: orderState.quantity,
      })
      setClientSecret(response.client_secret)
      setCheckoutSessionId(response.id)
    },
    [api, orderState.quantity]
  )

  const getChargedAmount = useCallback(async () => {
    if (!checkoutSessionId) {
      return
    }
    const response = await api.printify.getPaymentIntent(checkoutSessionId)
    setChargedAmount(response.amount)
    setChargedCustomerEmail(response.email)
    return response
  }, [api, checkoutSessionId])

  const onPhotoSelected = useCallback(
    (base64: string) => {
      setConfirmedOriginalDataUrl(base64)
      setCandidateDataUrl(base64)
      setIsCropping(true)
      navigate('personalize')
    },
    [navigate, setConfirmedOriginalDataUrl]
  )

  const onPersonalizeCompleted = useCallback(async () => {
    recordDidTapPreview({
      blueprintId,
    })

    if (!croppedDataUrl) {
      return
    }

    const orderId = await createBaseOrder(croppedDataUrl)
    if (orderId) {
      navigate(`preview/${orderId}`)
    }
  }, [createBaseOrder, navigate, recordDidTapPreview, blueprintId])

  const onPreviewCompleted = useCallback((orderId: string) => {
    recordDidTapShoppingCart({
      blueprintId: blueprint?.id,
    })
    navigate(`payment/${orderId}`)
  }, [])

  const onPaymentCompleted = useCallback(
    async (orderId: string) => {
      recordDidCompleteCheckout({
        blueprintId,
      })
      await getChargedAmount()
      navigate(`payment/${orderId}/success`)
    },
    [getChargedAmount, blueprintId, navigate, recordDidCompleteCheckout]
  )

  const onProductSelectionCompleted = useCallback(
    (payload: { quantity: number; variantId: number }) => {
      updateQuantityAndVariant(payload.quantity, payload.variantId)
      recordDidTapPersonalize({
        blueprintId,
      })
      if (croppedDataUrl) {
        navigate('personalize')
      } else {
        navigate('select-photo')
      }
    },
    [
      navigate,
      updateQuantityAndVariant,
      recordDidTapPersonalize,
      croppedDataUrl,
      blueprintId,
    ]
  )

  const onCartCompleted = useCallback(
    (orderId: string) => {
      recordDidTapToGetToCheckout({
        blueprintId,
      })
      navigate(`payment/${orderId}/checkout`)
    },
    [navigate, recordDidTapToGetToCheckout, blueprintId]
  )

  const onBackFromCartToPersonalize = useCallback(() => {
    setIsCropping(false)
    navigate('personalize')
  }, [navigate])

  const priceFormatter = useMemo(
    () => create2DecimalsCurrencyFormatter(currency),
    [currency]
  )

  const navigateFromCartToPersonalize = useCallback(() => {
    setIsCropping(false)
    navigate('personalize')
  }, [navigate])

  const navigateFromCartToProduct = useCallback(() => {
    navigate('.')
  }, [navigate])

  const navigateFromCardToProductsList = useCallback(() => {
    navigateBackToSource()
  }, [navigateBackToSource])

  const formattedPrices = useMemo(() => {
    const { variantId, quantity } = orderState
    const zeroPrice = `${priceFormatter(0)} ${currency}`

    if (!variantId) {
      return {
        price: zeroPrice,
        salePrice: zeroPrice,
        subtotal: zeroPrice,
        chargedAmount: zeroPrice,
      }
    }

    const { price, salePrice } = variants[variantId]
    const subtotal = quantity * (salePrice / 100)
    const formattedChargedAmount = `${priceFormatter(
      chargedAmount ? chargedAmount / 100 : 0
    )} ${currency}`

    return {
      price: `${priceFormatter(price / 100)} ${currency}`,
      salePrice: `${priceFormatter(salePrice / 100)} ${currency}`,
      subtotal: `${priceFormatter(subtotal)} ${currency}`,
      chargedAmount: formattedChargedAmount,
    }
  }, [
    priceFormatter,
    orderState.quantity,
    orderState.variantId,
    chargedAmount,
    blueprint?.variants,
    currency,
  ])

  const confirmCrop = useCallback(
    (base64url: string) => {
      setCroppedDataUrl(base64url)
      setConfirmedOriginalDataUrl(candidateDateUrl)
      setIsCropping(false)
    },
    [setCroppedDataUrl, candidateDateUrl, setConfirmedOriginalDataUrl]
  )

  const openCropDialog = useCallback(() => {
    setIsCropping(true)
  }, [setIsCropping])

  const closeCropDialog = useCallback(() => {
    setIsCropping(false)
    setCandidateDataUrl(null)
    if (!croppedDataUrl && confirmedOriginalDataUrl) {
      setConfirmedOriginalDataUrl(null)
    }
  }, [setIsCropping, croppedDataUrl, confirmedOriginalDataUrl])

  const onNewPhotoSelected = useCallback(
    async (base64: string) => {
      setCandidateDataUrl(base64)
      setIsCropping(true)
    },
    [onPhotoSelected]
  )

  const cartProducts = [
    {
      title: selectedVariant?.title || blueprintTitle,
      quantity: orderState.quantity,
      price: formattedPrices.salePrice,
      imageUrl:
        previewPhotosList.find((photo) => photo.isDefault)?.url ||
        previewPhotosList[0]?.url,
    },
  ]

  if (isLoading) {
    return <LoadingOverlay>Loading product information</LoadingOverlay>
  }

  if (!blueprint) {
    throw new Error('Blueprint not found')
  }

  return (
    <>
      <Routes>
        <Route
          index
          element={
            <ProductPage
              openTermsOfUsePage={openTermsOfUsePage}
              price={formattedPrices.price}
              salePrice={formattedPrices.salePrice}
              initialQuantity={orderState.quantity}
              initialVariantId={orderState.variantId}
              blueprint={blueprint}
              variants={variantProviders[0].variants}
              next={onProductSelectionCompleted}
              previous={navigateBackToSource}
              updateQuantityAndVariant={updateQuantityAndVariant}
            />
          }
        />
        <Route
          path="select-photo"
          element={
            <SelectPhotoPage
              api={api}
              next={onPhotoSelected}
              previous={() => navigate('.')}
            />
          }
        />
        <Route
          path="personalize"
          element={
            <PersonalizePage
              onNewPhotoSelected={onNewPhotoSelected}
              api={api}
              croppedDataUrl={croppedDataUrl}
              isCropping={isCropping}
              aspectRatio={selectedVariant?.aspectRatio || 1}
              candidateDataUrl={candidateDateUrl}
              confirmedOriginalDataUrl={confirmedOriginalDataUrl}
              confirmCrop={confirmCrop}
              openCropDialog={openCropDialog}
              closeCropDialog={closeCropDialog}
              next={onPersonalizeCompleted}
              previous={() => navigate('select-photo')}
            />
          }
        />
        <Route
          path="preview/:orderId"
          element={
            <PreviewPage
              isPrintifyPreviewLogsActive={isPrintifyPreviewLogsActive}
              openLogsPage={() => setIsLogsPageVisible(true)}
              showNotAllLoadedError={showNotAllLoadedError}
              isOrderLoaded={previewPhotosList.length > 0}
              previewPhotosList={previewPhotosList}
              loadOrder={loadOrder}
              previous={() => navigate('personalize')}
              next={onPreviewCompleted}
              regeneratePreview={regeneratePreview}
            />
          }
        />
        <Route
          path="payment/:orderId"
          element={
            <PaymentPage
              navigateBackToCart={(orderId: string) =>
                navigate(`payment/${orderId}`)
              }
              isOrderLoaded={previewPhotosList.length > 0}
              isGettingOrder={isGettingOrder}
              loadOrder={loadOrder}
            />
          }
        >
          <Route
            index
            element={
              <Cart
                hasMultipleColors={hasMultipleColors}
                hasMultipleSizes={hasMultipleSizes}
                navigateFromCartToPersonalize={navigateFromCartToPersonalize}
                navigateFromCartToProduct={navigateFromCartToProduct}
                navigateFromCardToProductsList={navigateFromCardToProductsList}
                onQuantityChange={updateQuantity}
                maxQuantity={10}
                next={onCartCompleted}
                previous={onBackFromCartToPersonalize}
                products={cartProducts}
                subtotal={formattedPrices.subtotal}
              />
            }
          />
          <Route
            path="checkout"
            element={
              <StripeCheckoutPage
                createPaymentIntent={createPaymentIntent}
                clientSecret={clientSecret}
                next={onPaymentCompleted}
              />
            }
          />
          <Route
            path="success"
            element={
              <OrderSuccess
                helpEmailSubject="Order Success"
                supportEmailAddress="global-support@family-album.com"
                chargedAmount={formattedPrices.chargedAmount}
                email={chargedCustomerEmail}
              />
            }
          />
        </Route>
      </Routes>
      {(isCreatingBaseOrder || isGettingOrder || isGettingPreview) && (
        <LoadingOverlay opaque animationType="progress-bar">
          Your product preview is being generated. This may take a few moments.
        </LoadingOverlay>
      )}
      {isPrintifyPreviewLogsActive && (
        <LogsPanel
          logs={fetchLogs}
          isOpen={isLogsPageVisible}
          close={() => setIsLogsPageVisible(false)}
        />
      )}
    </>
  )
}
