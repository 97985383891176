import { useCallback, useMemo, useState } from 'react'
import { Route, Routes, useSearchParams } from 'react-router-dom'
import ApiClient from '../../ApiClient'
import CreateFlow from './CreateFlow'
import TermsOfUsePanel from './components/TermsOfUsePanel'
import { useNavigate } from 'react-router-dom'
import PhotoGiftsProductList from '../../shared/PhotoGiftsProductList'

export default function PhotoProductsApp({
  api,
  isPrintifyPreviewLogsActive,
  sourcePath,
}: {
  api: ApiClient
  isPrintifyPreviewLogsActive: boolean
  sourcePath: string
}) {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const [uuid] = useState(searchParams.get('uuid') || '')
  const sourcePathWithUuid = useMemo(() => `${sourcePath}?uuid=${uuid}`, [uuid])

  const [isTermsOfUsePageVisible, setIsTermsOfUsePageVisible] = useState(false)

  const navigateBackToSource = useCallback(() => {
    navigate(sourcePathWithUuid)
  }, [navigate, sourcePath, uuid])

  return (
    <>
      <Routes>
        <Route
          index
          element={
            <PhotoGiftsProductList
              api={api}
              printifyProductsListLink="/photo-products"
            />
          }
        />
        <Route
          path="/:blueprintId/*"
          element={
            <CreateFlow
              api={api}
              navigateBackToSource={navigateBackToSource}
              openTermsOfUsePage={() => setIsTermsOfUsePageVisible(true)}
              isPrintifyPreviewLogsActive={isPrintifyPreviewLogsActive}
            />
          }
        />
      </Routes>
      <TermsOfUsePanel
        isOpen={isTermsOfUsePageVisible}
        close={() => setIsTermsOfUsePageVisible(false)}
      />
    </>
  )
}
