import { useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import ApiClient from '../../ApiClient'
import HeaderCatchCopy from '../../assets/photo-gifts/header_catch_copy.svg?react'
import headerCentralImage from '../../assets/photo-gifts/header_central_image.png'
import HeaderTitle from '../../assets/photo-gifts/header_title.svg?react'
import FaqItem from '../../components/FaqItem'
import FooterDisclaimer from '../../components/FooterDisclaimer'
import StickyFooter from '../../components/StickyFooter'
import LinkButton from '../../components/buttons/LinkButton'
import usePhotoGifts from '../../hooks/usePhotoGifts'
import useRecordUserAction, {
  C2AMetadata,
} from '../../hooks/useRecordUserAction'
import PhotoGiftsSection from '../grandparents-day/components/PhotoGiftsSection'

export default function PhotoGiftsLandingPage({
  api,
  supportEmail,
  printifyProductsListLink,
}: {
  api: ApiClient
  supportEmail: string
  printifyProductsListLink: string
}) {
  const [searchParams] = useSearchParams()
  const [uuid] = useState(searchParams.get('uuid') || '')
  const printifyProductsListUrlWithUuid = `${printifyProductsListLink}?uuid=${uuid}`

  const photoGifts = usePhotoGifts(printifyProductsListLink, uuid)
  const photoProductItems = photoGifts.getPhotoProductItems()

  const { recordDidTapC2A } = useRecordUserAction(api)

  const onCtaClick = useCallback(async (metadata: C2AMetadata) => {
    window.scrollTo(0, 0)
    recordDidTapC2A(metadata)
  }, [])

  const faqItems = [
    {
      question: 'Can I see a preview of my order?',
      answer:
        'Yes, all custom photo product orders with FamilyAlbum can be previewed before payment.',
    },
    {
      question: 'How long does it take to get my order?',
      answer:
        'Orders are typically delivered in 2 to 8 business days but shipping times may vary due to the shipping method you choose, seasonality, and more.',
    },
    {
      question: 'How much is shipping?',
      answer: (
        <>
          Shipping charges vary by product and location. Your shipping fees will
          be calculated on the checkout screen after you enter your shipping
          address.
          <br />
          Note: Photo Gifts are not eligible for free shipping for Premium
          users.
        </>
      ),
    },
    {
      question: 'What is your return policy?',
      answer: (
        <>
          This is a custom personalized product, therefore we do not offer
          refunds, returns, or exchanges except for orders with physical defects
          or damage. Damaged orders are eligible for a full refund within 30
          days of delivery. Please reach out to with a photo of your purchase to{' '}
          <a className="text-fa-primary" href={`mailto:${supportEmail}`}>
            {supportEmail}
          </a>{' '}
          to request a refund.
        </>
      ),
    },
  ]

  return (
    <div className="max-w-lg mx-auto">
      <section className="bg-[#FFDC00] w-full text-gray-12 text-center p-3 font-bold">
        Up to 20% OFF all Custom Photo Gifts
      </section>
      <section className="bg-[#FEFBE9] pt-8 pb-7">
        <HeaderTitle className="w-10/12 h-auto mx-auto mb-2" />
        <img src={headerCentralImage} className="w-10/12 mx-auto" />
        <HeaderCatchCopy className="w-9/12 h-auto mx-auto mt-4" />
      </section>
      <PhotoGiftsSection
        onProductClick={onCtaClick}
        photoProductItems={photoProductItems}
      />
      <section className="px-4 bg-white text-gray-12 mb-12 pt-8">
        <h2 className="font-bold text-2xl text-center mb-4">Shipping</h2>
        <div className="mb-4">
          <h3 className="font-bold mb-2">Cost:</h3>
          <p className="mb-2">
            Shipping charges and times vary by product type, location and
            method. Enter your shipping address at checkout for details.
          </p>
          <p className="text-fa-text-accent-secondary">
            Note: Photo Gifts are not eligible for free shipping for Premium
            users.
          </p>
        </div>
        <div className="mb-4">
          <h3 className="font-bold mb-2">Delivery:</h3>
          <p className="mb-2">
            Photo Gifts ship only within the U.S. Delivery to the mainland U.S.
            takes about 4 to 8 business days.* Tracking information will be sent
            via email once your order ships.
          </p>
          <p className="text-gray-11 text-[13px]">
            *For Alaska, Hawaii, Puerto Rico, and U.S. territories, please allow
            an additional 7 to 12 business days.
          </p>
        </div>
      </section>
      <section className="px-4 bg-white mt-4">
        <h2 className="font-bold text-2xl text-center mb-6">FAQ</h2>
        <div className="text-fa-text-secondary space-y-8">
          {faqItems.map((item) => (
            <FaqItem
              key={item.question}
              question={item.question}
              answer={item.answer}
            />
          ))}
        </div>
      </section>
      <footer className="px-4 pb-8 mt-12">
        <p>
          Have questions? Contact us at <br />
          <a className="text-fa-primary" href={`mailto:${supportEmail}`}>
            {supportEmail}
          </a>
        </p>
        <FooterDisclaimer />
      </footer>
      <StickyFooter>
        <LinkButton
          onClick={() => {
            onCtaClick({
              clickedElement: 'LP_FOOTER_CTA',
            })
          }}
          className="block p-2"
          colorVariant="primary"
          to={printifyProductsListUrlWithUuid}
        >
          Get started
        </LinkButton>
      </StickyFooter>
    </div>
  )
}
