import { useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import ApiClient from '../ApiClient'
import usePhotoGifts from '../hooks/usePhotoGifts'
import useRecordUserAction, { C2AMetadata } from '../hooks/useRecordUserAction'
import PhotoGiftsSection from '../pages/grandparents-day/components/PhotoGiftsSection'

export default function PhotoGiftsProductList({
  api,
  printifyProductsListLink,
}: {
  api: ApiClient
  printifyProductsListLink: string
}) {
  const [searchParams] = useSearchParams()
  const [uuid] = useState(searchParams.get('uuid') || '')

  const photoGifts = usePhotoGifts(printifyProductsListLink, uuid)
  const photoProductItems = photoGifts.getPhotoProductItems()

  const {
    photoProducts: { printify, divvyup },
  } = useRecordUserAction(api)

  const onProductClick = useCallback(
    (metadata: C2AMetadata) => {
      window.scrollTo(0, 0)
      if (metadata.blueprintId) {
        printify.recordDidSelectPhotoProduct(metadata)
      } else {
        divvyup.recordDidSelectPhotoProduct(metadata)
      }
    },
    [printify, divvyup]
  )

  return (
    <div className="max-w-lg mx-auto">
      <section className="pt-8 pb-4 px-4 text-black">
        <h2 className="text-center text-xl font-bold">Select a Photo Gift</h2>
      </section>
      <PhotoGiftsSection
        onProductClick={onProductClick}
        photoProductItems={photoProductItems}
      />
    </div>
  )
}
