import RebateBadge from '../../../components/RebateBadge'

export default function PhotoGiftProductCard({
  src,
  text,
  rebate,
}: {
  src: string
  text: string
  rebate?: string
}) {
  return (
    <div className="flex flex-col items-center bg-fa-bg-secondary rounded-lg h-full shadow-md">
      <div className="relative py-2">
        <img src={src} alt={text} className="h-[120px] mw-auto" />
        {rebate && (
          <RebateBadge rebate={rebate} className="absolute top-2 right-0" />
        )}
      </div>
      <div className="relative bg-white rounded-b-lg text-center text-black font-normal h-full w-full pt-2 pb-3">
        <p>{text}</p>
        <button className="bg-fa-bg-secondary rounded-2xl text-fa-primary font-bold mt-1 py-1.5 px-4">
          Create
        </button>
      </div>
    </div>
  )
}
